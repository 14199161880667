<template>
  <div v-if="landing" class="landing-section">
    <WizardForm
      v-if="landing"
      :landing="landing"
      @landing-changed="landingChanged"
    ></WizardForm>
  </div>
</template>

<script>
import WizardForm from './WizardForm'

export default {
  components: {
    WizardForm,
  },

  data() {
    return {
      landing: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.landing = JSON.parse(JSON.stringify(this.$store.getters['landing/landingOriginal']))
    
    if (this.$route.query.loop) {
      this.landing.type = 'loop'
    }
    if (this.$route.query.vote) {
      this.landing = JSON.parse(JSON.stringify(this.$store.getters['landing/voteOriginal']))
      this.landing.type = 'vote'
    }
  },

  methods: {
    landingChanged(landing) {
      this.landing = landing
    },
  },
}
</script>
